<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                    {{$t('FERIADOCADASTRO.SALVANDOCADASTRO')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('FERIADOCADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-one-third">
                                    <ValidationProvider rules="required" name="data" v-slot="{ errors, valid }">
                                        <b-field :label="$t('FERIADOCADASTRO.DATA')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-datepicker editable v-model="model.data" trap-focus></b-datepicker>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </article>
                    </div>                       
                    <div class="tile is-parent"> 
                        <article class="tile is-child">
                            <b-field :label="$t('FERIADOCADASTRO.ANO')">
                                <b-input type="text" v-model="ano"></b-input>
                            </b-field>
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="button" @click="importaFeriados()">
                                {{$t('FERIADOCADASTRO.FERIADOSNAC')}}
                            </b-button>
                        </article>  
                    </div>                
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('feriado-alterar')"
                                      :loading="salvandoDados"
                                      native-type="button" @click="verificaFeriado()"
                                      icon-left="check-circle">
                                {{$t('FERIADOCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('FERIADOCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    data: null
                },
                ano: new Date().getFullYear(),
                salvandoDados: false
            }
        },
        created() {
            if(this.$route.params.id){
                this.$http.get('/api/manutencao/feriado?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;
                        this.model.data = new Date(this.model.data);
                    });
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('FERIADOCADASTRO.MANUTENCAO'),
                    this.$t('FERIADOCADASTRO.FERIADO'),
                    this.model.id ?? 'novo'
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                this.salvandoDados = true;
                if(this.model.id == null){
                    this.model.id = 0
                }
                this.$http.post(`/api/manutencao/feriado`, this.model)
                    .then(() => {
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });
            },
            importaFeriados() {
                this.$http.get('/api/manutencao/gravaferiadosano?ano=' + this.ano);
            },
            verificaFeriado() {
                 let url = '/api/manutencao/verificaferiado?data=' + this.$moment(this.model.data).format();
                if(this.model.id != null & this.model.id >0){
                    url += '&id='+ this.model.id;
                }
                this.$http.get(url)
                    .then(res => {
                        if (res.data) {                            
                            this.onSubmit();
                        }else{
                            this.$buefy.dialog.alert({
                                title: this.$t('FERIADOCADASTRO.FERIADO'),
                                message: this.$t('FERIADOCADASTRO.JAEXISTE'),
                                type: 'is-warning',
                                hasIcon: true
                            });                            
                        }
                    });
            }
        }
    }
</script>