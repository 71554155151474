<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="box">
            <b-loading :active.sync="isLoading"></b-loading>
            <div class="columns ">
                <div class="column is-3">
                    <b-field label="Unidade">
                        <b-taginput
                            v-model="unidadesSelecionadas"
                            :data="unidadesLista"
                            autocomplete
                            :allow-new="false"
                            :before-adding="(item) => { this.unidadesLista = this.unidades; return item;}"
                            @input="loadData"
                            :open-on-focus="openOfFocus"
                            field="nome"
                            icon="label"
                            placeholder="Selecione a(s) unidade(s)"
                            @typing="buscarUnidade"
                            >
                            <template slot-scope="props">
                                <div class="media">   
                                    <div class="media-left">
                                        <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                    </div>
                                    <div class="media-content">
                                        {{ props.option.nome }}
                                    </div>                           
                                </div>
                            </template>
                        </b-taginput>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="Departamento">
                        <b-taginput
                            v-model="departamentosSelecionados"
                            :data="departamentosLista"
                            autocomplete
                            :allow-new="false"
                            :before-adding="(item) => { this.departamentosLista = this.departamentos; return item;}"
                            @input="loadData"
                            :open-on-focus="openOfFocus"
                            field="nome"
                            icon="label"
                            placeholder="Selecione o(s) departamento(s)"
                            @typing="buscarDepartamento"
                            >
                            <template slot-scope="props">
                                <div class="media">   
                                    <div class="media-left">
                                        <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                    </div>
                                    <div class="media-content">
                                        {{ props.option.nome }}
                                    </div>                           
                                </div>
                            </template>
                        </b-taginput>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="Setor">
                        <b-taginput
                            v-model="setoresSelecionados"
                            :data="setoresLista"
                            autocomplete
                            :allow-new="false"
                            :before-adding="(item) => { this.setoresLista = this.setores; return item;}"
                            @input="loadData"
                            :open-on-focus="openOfFocus"
                            field="nome"
                            icon="label"
                            placeholder="Selecione o(s) setor(es)"
                            @typing="buscarSetor"
                            >
                            <template slot-scope="props">
                                <div class="media">   
                                    <div class="media-left">
                                        <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                    </div>
                                    <div class="media-content">
                                        {{ props.option.nome }}
                                    </div>                           
                                </div>
                            </template>
                        </b-taginput>
                    </b-field>
                </div>                
                <div class="column is-3">
                    <b-field label="Convenio">
                        <b-taginput
                            v-model="conveniosSelecionados"
                            :data="conveniosLista"
                            autocomplete
                            :allow-new="false"
                            :before-adding="(item) => { this.conveniosLista = this.convenios; return item;}"
                            @input="loadData"
                            :open-on-focus="openOfFocus"
                            field="nome"
                            icon="label"
                            placeholder="Selecione o(s) convenio(s)"
                            @typing="buscarConvenio"
                            >
                            <template slot-scope="props">
                                <div class="media">   
                                    <div class="media-left">
                                        <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                    </div>
                                    <div class="media-content">
                                        {{ props.option.nome }}
                                    </div>                           
                                </div>
                            </template>
                        </b-taginput>
                    </b-field>
                </div>
            </div>
            <div class="columns ">
                <div class="column is-3">
                    <b-field label="Local">
                        <b-taginput
                            v-model="locaisSelecionados"
                            :data="locaisLista"
                            autocomplete
                            :allow-new="false"
                            :before-adding="(item) => { this.locaisLista = this.locais; return item;}"
                            @input="loadData"
                            :open-on-focus="openOfFocus"
                            field="nome"
                            icon="label"
                            placeholder="Selecione o(s) local(is)"
                            @typing="buscarLocal"
                            >
                            <template slot-scope="props">
                                <div class="media">   
                                    <div class="media-left">
                                        <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                    </div>
                                    <div class="media-content">
                                        {{ props.option.nome }}
                                    </div>                           
                                </div>
                            </template>
                        </b-taginput>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="Visualizar">
                        <b-select v-model="dias" @input="loadData" expanded>
                            <option value="1">últimas 24 horas</option>
                            <option value="2">últimos 2 dias</option>
                            <option value="3">últimos 3 dias</option>
                            <option value="5">últimos 5 dias</option>
                            <option value="7">últimos 7 dias</option>
                            <option value="10">últimos 10 dias</option>
                            <option value="15">últimos 15 dias</option>
                            <option value="20">últimos 20 dias</option>
                            <option value="30">últimos 30 dias</option>
                            <option value="45">últimos 45 dias</option>
                            <option value="60">últimos 60 dias</option>
                            <option value="90">últimos 90 dias</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="Atualizar">
                        <b-select v-model="intervalo" @input="setTimer" expanded>
                            <option value="0">Nunca</option>
                            <option value="1">Cada minuto</option>
                            <option value="2">Cada 2 minutos</option>
                            <option value="5">Cada 5 minutos</option>
                            <option value="10">Cada 10 minutos</option>
                            <option value="20">Cada 20 minutos</option>
                            <option value="30">Cada 30 minutos</option>
                        </b-select>
                    </b-field>
                </div>                           
            </div>
            <div class="columns ">
                <div class="column is-4"> 
                    <b-switch v-model="naoExibeMaterialEntregue" @input="loadData">
                            Não exibir exames coletados
                    </b-switch>
                </div> 
            </div>                

        </div>
        <b-notification  v-show="!isLoading && lista.length == 0">Não foram encontradas guias com os filtros selecionados</b-notification>
        <b-table :data="lista"
                 :loading="isLoading"
                 striped
                 hoverable
                 :row-class="(row, index) => calculaClasse(row.dataDeRetorno)">

            <template  class="">
                <b-table-column  v-slot="props" field="guiaId" label="Guia">
                    <router-link :to="'/atendimento/guia/'+props.row.guiaId" class="tag is-info is-small is-pulled-right">
                        {{ props.row.guiaId }}
                    </router-link>
                </b-table-column>

                <b-table-column  v-slot="props" field="Paciente" label="Paciente">
                    {{ props.row.pacienteNome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Convenio" label="Convênio">
                    {{ props.row.convenioNome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Local" label="Local">
                    {{ props.row.localNome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Setor" label="Setor">
                    {{ props.row.setorNome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Exames" label="Exames" ellipsis>
                    <span v-for="(item, index) in props.row.examesApelidos" v-bind:key="index" class="tag is-default">
                        {{item}}<span> </span>
                    </span>
                </b-table-column>

                <b-table-column  v-slot="props" field="Retorno" label="Retorno previsto">
                    {{ $moment(props.row.dataDeRetorno).format("DD/MM/YYYY HH:mm") }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Tempo" label="">
                    {{ calculaTempo(props.row.dataDeRetorno) }}
                </b-table-column>

            </template>
        </b-table>
    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'
    import { mapState } from 'vuex'

    export default {
        data() {
            return {
                dias: 1,
                intervalo: 0,
                timer: null,
                isLoading: false,
                lista: [],
                setores: [],
                convenios: [],
                locais: [],
                departamentos: [],
                unidadesSelecionadas: [],
                unidadesLista: this.unidades,
                departamentosSelecionados: [],
                departamentosLista: this.departamentos,
                setoresSelecionados: [],
                setoresLista: this.setores,
                conveniosSelecionados: [],
                conveniosLista: this.convenios,
                locaisSelecionados: [],
                locaisLista: this.locais,
                openOfFocus: true,
                naoExibeMaterialEntregue: false,
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() { 
                return [
                    'Análise',
                    'Entregas'
                ]
            },
            ...mapState([
                'config',
                'unidades'
            ])
        },
        mounted() {
            this.loadDepartamentos();
            this.loadSetores();
            this.loadConvenios();
            this.loadLocais();
            this.loadData();
        },
        beforeDestroy() {
            if (this.timer != null) {
                clearInterval(this.timer)
            }
        },
        methods: {
            buscarUnidade(text) {
                this.unidadesLista = this.unidades;
                this.unidadesLista = this.unidadesLista.filter((option) => {
                    return option.nome
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                })
            },
            buscarDepartamento(text) {
                this.departamentosLista = this.departamentos;
                this.departamentosLista = this.departamentosLista.filter((option) => {
                    return option.nome
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                })
            },
            buscarSetor(text) {
                this.setoresLista = this.setores;
                this.setoresLista = this.setoresLista.filter((option) => {
                    return option.nome
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                })
            },
            buscarConvenio(text) {
                this.conveniosLista = this.convenios;
                this.conveniosLista = this.conveniosLista.filter((option) => {
                    if(option.nome)
                        return option.nome
                            .toString()
                            .toLowerCase()
                            .indexOf(text.toLowerCase()) >= 0
                })
            },
            buscarLocal(text) {
                this.locaisLista = this.locais;
                this.locaisLista = this.locaisLista.filter((option) => {
                    if(option.nome)
                        return option.nome
                            .toString()
                            .toLowerCase()
                            .indexOf(text.toLowerCase()) >= 0
                })
            },
            calculaClasse(dataDeRetorno){
                let diff = new Date(dataDeRetorno).getTime() - new Date().getTime();

                const days = Math.floor(diff / (1000 * 60 * 60 * 24));
                diff -=  days * (1000 * 60 * 60 * 24);

                const hours = Math.floor(diff / (1000 * 60 * 60));
                diff -= hours * (1000 * 60 * 60);

                if(days < 0){
                    return 'analise-entregas-atraso'
                }
                else if(days == 0 && hours < 2){
                    return 'analise-entregas-alerta'
                }
                else {
                    return ''
                }
            },
            calculaTempo(dataDeRetorno){
                let diff = new Date(dataDeRetorno).getTime() - new Date().getTime();

                const days = Math.floor(diff / (1000 * 60 * 60 * 24));
                diff -=  days * (1000 * 60 * 60 * 24);

                const hours = Math.floor(diff / (1000 * 60 * 60));
                diff -= hours * (1000 * 60 * 60);

                const mins = Math.floor(diff / (1000 * 60));
                diff -= mins * (1000 * 60);

                const seconds = Math.floor(diff / (1000));
                diff -= seconds * (1000);

                return days + " dias, " + hours + " horas, " + mins + " minutos, " + seconds + " segundos";
            },
            setTimer() {
                if (this.timer != null) {
                    clearInterval(this.timer)
                }
                if (this.intervalo > 0) {
                    this.timer = setInterval(this.loadData, this.intervalo * 60 * 1000)
                }
            },
            loadDepartamentos(){
                this.$http.get('/api/search/departamentos')
                    .then(res => {
                        this.departamentos = res.data;
                    });
            },
            loadSetores(){
                this.$http.get('/api/search/setores')
                    .then(res => {
                        this.setores = res.data;
                    });
            },
            loadConvenios(){
                this.$http.get('/api/search/convenios')
                    .then(res => {
                        this.convenios = res.data;
                    });
            },
            loadLocais(){
                this.$http.get('/api/search/LocaisDeOrigem')
                    .then(res => {
                        this.locais = res.data;
                    });
            },
            loadData(){
                const params = [
                    `dias=${this.dias}`
                ]

                if(this.unidadesSelecionadas.length > 0){
                    params.push('unidadeId=' + this.unidadesSelecionadas.map(item => item.id).join());
                }

                if(this.departamentosSelecionados.length > 0){
                    params.push('departamentoId=' + this.departamentosSelecionados.map(item => item.id).join());
                }

                if(this.setoresSelecionados.length > 0){
                    params.push('setorId=' + this.setoresSelecionados.map(item => item.id).join());
                }

                if(this.conveniosSelecionados.length > 0){
                    params.push('convenioId=' + this.conveniosSelecionados.map(item => item.id).join());
                }

                if(this.locaisSelecionados.length > 0){
                    params.push('localId=' + this.locaisSelecionados.map(item => item.id).join());
                }

                 if(this.naoExibeMaterialEntregue){
                    params.push('naoExibeMaterialEntregue=' + this.naoExibeMaterialEntregue);
                }

                this.isLoading = true
                this.$http.get(`/api/analitico/examesEntregas?${params.join('&')}`)
                    .then(({ data }) => {
                        this.lista = data;
                        this.isLoading = false
                    })
                    .catch((error) => {
                        this.lista = []
                        this.isLoading = false
                        throw error
                    })
            }
        }
    }

</script>