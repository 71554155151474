<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                   {{$t('TIPOCODIGOCADASTRO.SALVANDOTIPO')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('TIPOCODIGOCADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </article>
                    </div>                    
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success" v-show="isInRole('exameCodigoTipo-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('TIPOCODIGOCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('TIPOCODIGOCADASTRO.CANCELAR')}} 
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null
                },
                salvandoDados: false
            }
        },
        created() {
            if(this.$route.params.id){
                this.$http.get('/api/manutencao/examecodigotipo?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;
                    });
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('TIPOCODIGOCADASTRO.MANUTENCAO'),
                    this.$t('TIPOCODIGOCADASTRO.TIPOSCODIGO'),
                    this.model.id ?? 'novo'
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                this.salvandoDados = true;
                if(this.model.id == null){
                    this.model.id = 0
                }
                this.$http.post(`/api/manutencao/examecodigotipo`, this.model)
                    .then(() => {
                        this.salvandoDados = false
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false
                        throw error
                    });
            }
        }
    }
</script>