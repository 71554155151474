<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                   {{$t('UNIDADEDEMEDIDACADASTRO.SALVANDO')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-one-third">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('UNIDADEDEMEDIDACADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-one-third">
                                    <ValidationProvider rules="required" name="código ans" v-slot="{ errors, valid }">
                                        <b-field :label="$t('UNIDADEDEMEDIDACADASTRO.CODIGOANS')"  
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.codigoAns"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>  
                            <div class="columns">
                                <div class="column is-one-third">
                                    <b-field :label="$t('UNIDADEDEMEDIDACADASTRO.UNIDADEDEMEDIDADEREFERENCIA')">
                                        <b-select :placeholder="$t('UNIDADEDEMEDIDACADASTRO.SELECIONEUNIDADE')" 
                                        v-model="model.unidadeDeMedidaDeReferencia.id" expanded
                                        @input="changed()"  >
                                            <option id=-1 value = -1> </option>
                                            <option v-for="(option, index) in unidadesDeMedida"
                                                    :value="option.id" v-bind:key="index">
                                                {{ option.nome }}
                                            </option>                                            
                                        </b-select>
                              
                                    </b-field>
                                </div>
                                <div class="column is-one-third">
                                    <ValidationProvider rules="required" name="model.unidadeDeMedidaDeReferencia.fator" v-slot="{ errors, valid }">
                                        <b-field :label="$t('UNIDADEDEMEDIDACADASTRO.FATOR')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="number" step="0.01" v-model="model.fator"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>                         
                        </article>                
                    </div>                    
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success" v-show="isInRole('estoque-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('UNIDADEDEMEDIDACADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('UNIDADEDEMEDIDACADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar
        },
        data() {
            return {
                model: {
                    id: (this.$route.params.id) ? this.$route.params.id : 0,
                    nome: null,
                    codigoAns: null,
                    fator: 0.0,
                    unidadeDeMedidaDeReferencia: {
                        id: 0,
                        nome: null,
                        fator: null
                    }
                },     
                unidadesDeMedida: [],           
                salvandoDados: false,
                retornarUnidadeMedida: Boolean(this.$route.query.retornarUnidadeMedida) ?? false,
            }
        },
        created() {
            
            if(this.model.id>=0){
                this.$http.get('/api/estoque/UnidadeDeMedida?id=' + this.model.id)
                    .then(res => {                        
                        this.model = res.data.unidadeDeMedida;
                        this.unidadesDeMedida = res.data.unidadesDeMedida;
                    });                
            }
            

        },
        computed: {
            titleStack() {
                return [
                    this.$t('ESTOQUE.TITULO'),
                    this.$t('UNIDADEDEMEDIDACADASTRO.TITULO'),
                    this.$route.params.id ?? 'novo'
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            changed(){

                if(this.model.unidadeDeMedidaDeReferencia.id > 0){
                    this.model.unidadeDeMedidaDeReferencia.nome = 
                    this.unidadesDeMedida.filter(x => x.id == this.model.unidadeDeMedidaDeReferencia.id)[0].nome;                    
                }else{
                    this.model.unidadeDeMedidaDeReferencia.nome = 'nenhum';
                }

            },
            onSubmit() {
                this.salvandoDados = true;
                let nomePreenchido = true;

                if(this.model.id == null){
                    this.model.id = 0
                }
           
                if(!this.model.nome){
                    nomePreenchido= false;
                }
          
                if(nomePreenchido){
            

                    this.$http.post(`/api/estoque/unidadedemedida`, this.model)
                            .then((res) => {
                                this.salvandoDados = false;
                                if(this.retornarUnidadeMedida){
                                    localStorage.retornarUnidadeMedida = res.data.id;   
                                    window.close();
                                }else{
                                    this.$router.back();
                                }

                            })
                            .catch((error) => {
                                this.salvandoDados = false;
                                throw error
                            });                      

                }else{
                
                        this.$buefy.dialog.alert({
                            title: this.$t('UNIDADEDEMEDIDACADASTRO.ATENCAO'),
                            message: this.$t('UNIDADEDEMEDIDACADASTRO.CAMPOSOBRIGATORIOS'),
                            type: 'is-warning',
                            hasIcon: true                                          
                        });                    
                        this.salvandoDados = false;
                        return null;
                }
                
 

            }
        }
    }
</script>