<template>
    <section>        
        <b-loading :active.sync="isLoading"></b-loading>
        <titlebar :title-stack="titleStack"></titlebar>

        <b-modal :active.sync="isModalAgendamento" has-modal-card trap-focus aria-role="dialog" aria-modal full-screen>
            <modalNovoAgendamento :id="novo.id" :observacao="novo.observacao" :data.sync="novo.data" :habilitarVisaoDiario="true"  
            :idTipoAgendamento.sync="novo.idTipoAgendamento" :idPaciente.sync="novo.idPaciente" :paciente.sync="novo.paciente" 
            :tipoAgendamento.sync="novo.tipoAgendamento" :funcionarioId.sync="novo.funcionarioId" :funcionariosAtivos.sync="novo.funcionariosAtivos" 
            @novo="adicionarAgendamento"></modalNovoAgendamento>
        </b-modal>

        <div class='box' >
            <div class="level">
                <div class="level-item">

                    <div>
                        <p class="heading">Guia</p>
                        <p class="title">

                            <router-link :to="{ name: 'guia', params: { id: this.id}}">
                                {{ this.id}}
                            </router-link>                      

                        </p>
                    </div>

                </div>
                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">Paciente</p>
                        <p class="title">{{this.model.agendaWorklist[0].pacienteNome}}</p>
                    </div>
                </div>
                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">Convênio</p>
                        <p class="title">{{this.model.agendaWorklist[0].convenioNome}}</p>
                    </div>
                </div>       
                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">Solicitante</p>
                        <p class="title">{{this.model.agendaWorklist[0].medicoNome}}</p>
                    </div>
                </div>                                               
                <div class="level-item">
                    <b-button type="is-primary" @click="abrirAgenda()">
                        <b-icon icon="calendar"></b-icon>  <span>Ir para agenda</span>
                    </b-button>                    
                </div>                
            </div>
        </div>        

        <article class="panel">
            <div class="panel-heading has-text-centered">                
                {{$t('WORKLIST.AGENDAMENTOEXAMES')}}
            </div>
            <b-table :data="model.agendaWorklist"                                            
                    focusable >

                <template  >
         
                  <b-table-column v-slot="props" field="examenome" :label="$t('WORKLIST.EXAME')" >
                         {{ props.row.exameNome }}
                    </b-table-column>   
                  <b-table-column v-slot="props" field="tipoagendamento" :label="$t('WORKLIST.AGENDA')" >
                         {{ props.row.tipoAgendamentoNome }}
                        <b-icon v-if="props.row.tipoAgendamentoCor"
                                :style="tipoAgendamentoStyle(props.row.tipoAgendamentoCor)"    
                                class="is-medium" icon="square-rounded"></b-icon>
                        <b v-if="!props.row.pacienteConfirmado" :title="$t('AGENDA.PACIENTENAOCONFIRMADO')" class="has-text-danger">!</b> 
                        <b v-else :title="$t('AGENDA.PACIENTECONFIRMADO')" class="has-text-success">&radic;</b>                                                          
                    </b-table-column>           
                  <b-table-column v-slot="props" field="agendamento" :label="$t('WORKLIST.AGENDAMENTOREALIZADO')"  >
                          
                          <span v-if="props.row.agendamento"> <span>{{ props.row.agendamento }}</span> </span>
                                                    
                    </b-table-column> 
                  <b-table-column v-slot="props" field="checkin" :label="$t('WORKLIST.CHECKIN')"  >
                        <b-checkbox
                            v-if="props.row.agendamento"                            
                            v-model="props.row.pacienteCheckin"                            
                            :disabled="props.row.pacienteCheckin"
                            @input="fazerCheckin(props.row)"
                            expanded
                        ></b-checkbox>

                        <b-button v-if="props.row.pacienteCheckin" :title="$t('WORKLIST.REENVIARWORKLIST')"
                                    @click="reenviarWorklist(props.row)" size="is-small"
                                    icon-left="refresh"></b-button>
                                          
                    </b-table-column>                      
                </template>                                                                                                            
    
            </b-table>            
        </article>

        <div v-show="!isLoading" class="columns whitebox">                                     
            
                <div style="border-right: 2px #ced8ce solid;" class="column is-4 is-fullwidth-mobile" v-for="(agenda, index) in model.agendaWorklist.filter(a=> (a.agendamento ==null || editar==1))" v-bind:key="index">                                   
                        <div class="p-0 column is-12 has-text-centered whitebox" > 
                                
                                <span class="is-size-"><b>{{agenda.tipoAgendamentoNome}}</b></span>                                                         

                        </div>  
                        <div class="column is-12 has-text-centered is-inline-block" > 

                                <b-button class="is-pulled-left"  icon-left="chevron-left" @click="addDays(-1,agenda);" ></b-button>                                    
                                <span class="is-size-"><b>{{$moment(agenda.dataDiaFiltro).format('DD/MM/YYYY') }} - {{ getDayOfWeek(agenda.dataDiaFiltro)}}</b></span>                                     
                                <b-button class="is-pulled-right" icon-left="chevron-right"  @click="addDays(1,agenda);" ></b-button> 

                        </div>  
                    
                        <agenda-item v-if="agenda.eventosDia.length>0" :eventos="agenda.eventosDia" :tipoVisao="'dia'" v-on:loadData="loadDataDia()" :agendaWorklist="true"
                             @incluirNovoHorarioDia="incluirNovoHorarioDia" ></agenda-item> 

                        <div v-else class="notification column is-12 is-warning">
                            <h4>{{$t('AGENDA.ESCALASNAOCADASTRADAS')}}</h4>                                    
                        </div>                                           
                </div>                                                                                                                              
        </div>     

        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                                :loading="salvandoDados"
                                @click="salvarWorklist"
                                icon-left="check-circle">
                        {{$t('WORKLIST.SALVAR')}}
                    </b-button>
                </p>
            </div>
        </nav>             

    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'
    import { mapState } from 'vuex'
    import agendaItem from '@/components/atendimento/agenda-item.vue';    
    import moment from 'moment';  
    import modalNovoAgendamento from '@/components/atendimento/modalNovoAgendamento.vue';    


    export default {
        data() {
            return {
                id: this.$route.params.id,
                pagamentoGuia: this.$route.params.pagamentoGuia,
                condicaoPagamento: this.$route.params.condicaoPagamento,
                convenioCoparticipacao: this.$route.params.convenioCoparticipacao,                
                selected:null,
                model: [],
                tipoAgendamentoIdFiltro: 27,                
                isLoading: false,                  
                isModalAgendamento: false,                                
                imprimir: { id: null },
                novo: {
                    data: null,
                    observacao: null,
                    idPaciente: null,
                    idTipoAgendamento: null,
                    funcionarioId: null,
                    paciente: {},
                    tipoAgendamento: {}
                },                                                          
            }
        },
        components: {
            titlebar,
            agendaItem,
            modalNovoAgendamento            
        },
        computed: {
            titleStack() {
                return [
                    this.$t('WORKLIST.ATENDIMENTO'),
                    this.$t('WORKLIST.WORKLIST'),
                    this.id
                ]
            },
            ...mapState([
                'config',
            ])
        },
        watch: {
            selected: function(selected) {                            
                this.$forceUpdate();
                this.loadDataDia();                                                
            }
        },          
        created() {
            this.salvandoDados = true;            
            this.carregarWorklist();
            
                   
        },
        methods: {


            abrirAgenda(){
                const routeData = this.$router.resolve({name: 'agenda'});
                window.open(routeData.href, '_blank');                
            },
            async agendaStatus(evento,acao) {
                const params = [];
                if (evento.id) params.push(`id=${evento.id}`);

                params.push(`acao=${acao}`);

                try {
                    const url = `/api/atendimento/AgendaStatus?${params.join('&')}`;
                    const res = await this.$http.get(url);
                    this.carregarWorklist();
                } catch (e) {
                    console.error(e);
                }

            },     
            async reenvioWorklist(evento) {
                const params = [];
                if (evento.id) params.push(`id=${evento.id}`);
                
                try {
                    const url = `/api/atendimento/reenviarWorklist?${params.join('&')}`;
                    const res = await this.$http.get(url);                    
                } catch (e) {
                    console.error(e);
                }

            },                                
            reenviarWorklist(item){

                this.$buefy.dialog.confirm({
                    title: this.$t('WORKLIST.WORKLIST'),
                    message: this.$t('WORKLIST.REENVIARWORKLIST'),
                    type: 'is-info',
                    hasIcon: true,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    onConfirm: () => {                        
                        this.reenvioWorklist({id: item.agendamentoId});
                    },
                                    
                })

                return true;
            },            
            fazerCheckin(item){

                this.$buefy.dialog.confirm({
                    title: this.$t('WORKLIST.CHECKIN'),
                    message: this.$t('WORKLIST.CONFIRMARCHECKIN'),
                    type: 'is-info',
                    hasIcon: true,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    onConfirm: () => {                        
                        this.agendaStatus({id: item.agendamentoId},'checkin');
                    },
                    onCancel: () => {
                        item.pacienteCheckin = false;
                    },
                    
                })

                return true;
            },
            carregarWorklist(){
                this.$http.get(`/api/atendimento/Worklist?id=${this.$route.params.id}`)
                    .then(({ data }) => {
                        this.model = data;

                        this.model.agendaWorklist = data.agendaWorklist
                            .map(d => {
                                return {
                                    ...d,
                                    agendamento: (d.agendamento) ? this.$moment(d.agendamento).format('DD/MM/YYYY HH:mm') : null,
                                    eventosDia: [],
                                    dataDiaFiltro:  new Date(new Date().setHours(0,0,0,0))                                    
                                }                                           
                            }); 

                        this.loadDataDia();
                        this.salvandoDados = false;
                                                                                                
                    })
                    .catch((error) => {
                        this.model = null
                        throw error
                    })
                    .finally(() => {
                        this.salvandoDados = false
                    }) 
            },
            tipoAgendamentoStyle(cor) {
                return 'color: #' + cor + '!important'
            },     
            salvarWorklist(){

                    let finalizarAgendamento = true;
                    this.model.agendaWorklist.forEach(e => {
                        if(e.agendamento == null)
                           finalizarAgendamento = false; 

                        
                    });
                    
                    if(finalizarAgendamento){
                        
                            if(this.pagamentoGuia && (this.condicaoPagamento  != 0 || this.convenioCoparticipacao)){
                                this.$router.push({ name: 'contas-receber-particular', 
                                    params: { 
                                        guiaId: this.id, 
                                        condicaoPagamento: this.condicaoPagamento
                                    }
                                });
                            }
                            else if (this.id) {
                                this.$router.push({ name: 'workflow', 
                                    params: { 
                                        id: this.id
                                    }
                                });
                            }

                    }else{
                        this.$buefy.dialog.alert({
                            title: 'Atenção',
                            message: 'Existem Exames que ainda não foram agendados!',
                            type: 'is-warning',
                            hasIcon: true
                        });
                    }                

                return true;
            },  

            getDayOfWeek(dataDiaFiltro){ 
                const day = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];                 
                return day[this.$moment(dataDiaFiltro).toDate().getDay()];
            },            
            
            addDays(dia,agenda){

                agenda.dataDiaFiltro.setDate(agenda.dataDiaFiltro.getDate() + dia);                                
                this.loadDataDia();                                
            }, 
            getEvento(d) {
                return {
                    ...d,
                    atendimentoInterno: !d.guiaTipoDeLocalColetaDomiciliar,
                    atendimentoDomiciliar: d.guiaTipoDeLocalColetaDomiciliar,
                    pacienteId: this.model.agendaWorklist.pacienteId,                    
                    day: this.$moment(d.dataHora).format('YYYY-MM-DD'),
                    hour: this.$moment(d.dataHora).format('HH:mm'),
                    data: this.$moment(d.dataHora)
                }
            },            
            loadDataDia(){
                
                this.model.agendaWorklist?.forEach(a => { 
                    
                    const params = [];    
                    if (a.dataDiaFiltro != null) params.push(`dataDiaFiltro=${this.$moment(a.dataDiaFiltro).format()}`);                                                     
                    if(a.tipoAgendamentoId) params.push(`tipoAgendamentoId=${a.tipoAgendamentoId}`);
                    this.isLoading = true;

                    try {
                        this.$http.get(`/api/atendimento/AgendaDia?${params.join('&')}`)
                            .then(res => res.data)
                            .then(data => {
                                if (data != null) {
                                    a.eventosDia = data?.lista                                    
                                        .map(d => {
                                            return this.getEvento(d);
                                        });
                                    
                                    a.eventosDia = a.eventosDia.filter(e=>e.pacienteNome ==null);

                                }
                                this.isLoading = false;    
                                this.$forceUpdate();                                                                                  
                            }).catch(e => {
                                throw e;
                            }).finally(() => {
                                    this.model.agendaWorklist.forEach(a => {
                                        if(a.eventosDia.length==0){
                                            a.dataDiaFiltro.setDate(a.dataDiaFiltro.getDate() + 1); 
                                            this.loadDataDia();                                
                                        }                                                                                
                                    });                                    
                                    
                            });
                            
                    } catch (e) {
                        console.error(e);
                        this.isLoading = false;
                    }                      
                        
                });

                              
            },  
            incluirNovoHorarioDia(evento, horario){      
                
                const item = {
                    id: evento.id,
                    paciente: {
                        nome: this.model.agendaWorklist[0].pacienteNome,
                        id: this.model.agendaWorklist[0].pacienteId,
                    },
                    tipoAgendamento: {
                        nome: evento.tipoAgendamentoNome,
                        id: evento.tipoAgendamentoId,
                        cor: evento.cor
                    },
                    idPaciente: evento.pacienteId,                    
                    idTipoAgendamento: evento.tipoAgendamentoId,                    
                    data: horario,
                    idUnidade : this.model.agendaWorklist[0].unidadeId
                }  
                                              
                this.$buefy.dialog.confirm({
                    title: "Realizar Agendamento?",
                    message:
                        "Agendar o paciente <b>"+item.paciente.nome+"</b> na data de <b>" + moment(item.data).format('DD/MM/YYYY HH:mm') +  "</b> ?",
                    confirmText: "Confirmar",
                    type: "is-info",
                    hasIcon: true,
                    cancelText: "Cancelar",
                    onCancel: function () {
                        return;
                    },                           
                    onConfirm: () => {
                        this.adicionarAgendamento(item);

                    },
                    
                });

                
            },
            
            async adicionarAgendamento(evento) {
                const params = [];
                if (evento.id) params.push(`id=${evento.id}`);
                params.push(`pacienteId=${evento.paciente.id}`);
                params.push(`tipoAgendamentoId=${evento.tipoAgendamento.id}`);
                if(evento.funcionarioId == null) evento.funcionarioId = 0;
                params.push(`funcionarioId=${evento.funcionarioId}`);
                params.push(`datahora=${moment(evento.data).format('YYYY-MM-DDTHH:mm:ss')}`);
                params.push(`observacao=${evento.observacao ? evento.observacao : ''}`);
                params.push(`unidadeId=${evento.idUnidade}`);

                if (this.id) params.push(`guiaId=${this.id}`);
                
                try {
                    let url = `/api/atendimento/agendaincluir?${params.join('&')}`;

                    if (evento.id) {
                        url = `/api/atendimento/agendaEditar?${params.join('&')}`;
                    }

                    this.isLoading = true;
                    const res = await this.$http.post(url)
                    .then(res => res.data)
                    .then(data => {
                        if (data.mensagem != null && data.mensagem != "") {
                           this.$buefy.toast.open({
                            duration: 5000,
                            message: data.mensagem,
                            type: 'is-danger',
                            queue: false
                        })
                        }                                    
                    });   

                    this.carregarWorklist();                 
                    this.loadDataDia();
                } catch (e) {                    
                    console.error(e);
                    this.isLoading = false;
                }

            },                                 
        }
    }
</script>