<template>
  <section>
    <titlebar :title-stack="titleStack"></titlebar>
    <div class="box">
      <b-loading :active.sync="isLoading"></b-loading>
      <label v-show="amostraSelecionada.length >0"><b>{{ $t('SOROTECA.AMOSTRASSELECIONADAS') }} </b>

        <span class="tag cell-size input-text-size is-default" 
          v-for="amostra in amostraSelecionada" :key="amostra">
            {{ amostra }}
        </span>         
      
      </label>
   
      <div class="columns">
        <div class="column is-6">          
          <searchIdName
            :ponto="false"
            :label="$t('SOROTECA.GELADEIRA')"
            table="Geladeira"
            :id.sync="item.geladeiraId"
          ></searchIdName>
        </div>
        <div class="column is-6">          
          <searchIdName
            :ponto="false"
            :label="$t('SOROTECA.CONTAINER')"
            table="Container"
            :id.sync="item.containerId"
          ></searchIdName>
        </div>
      </div>
      <nav class="level">
      <div class="level-item">
        <p class="buttons">
          <b-button
          type="is-primary"
          icon-left="filter"
          class="center"
          @click.prevent.stop="search">
            {{ $t('SOROTECA.FILTRAR') }}
          </b-button>
        </p>
      </div>
    </nav>
    </div>

    <div class="box" v-if="model.posicoes != null && model.posicoes.length > 0">
      <div class="tile is-child box">
        <div class="column">
               <zoom :elemento="this.$refs.table" :max="1.0" :min="0.5" />
        </div>
        <label><b>{{ $t('SOROTECA.POSICOESTITULO') }}</b></label>
        <table class="table" ref="table">
          <thead>
            <tr>
              <th></th>
              <th v-for="(coluna, x) in colunas" v-bind:key="changeId(x)">
                <label class="label">{{ colunas[x] }}</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(linha, i) in linhas" v-bind:key="changeId(i)">
              <td>
                <label class="label">{{ linhas[i] }}</label>
              </td>
              <td v-for="(coluna, j) in colunas" v-bind:key="changeId(j)">
                <b-field
                  label=""
                  class="cell-size"
                  :type="{
                    'is-danger': posicao(linhas[i], colunas[j]).error,
                  }"
                  :message="posicao(linhas[i], colunas[j]).error"
                >
                  <b-input
                    :id="changeId()"
                    :value="posicao(linhas[i], colunas[j]).valor"
                    @keydown.native.enter.prevent="setarValor(getOrdem(colunas.length,i,j),linhas[i], colunas[j], $event)"
                    custom-class="input-text-size"      
                    :ref ="getOrdem(colunas.length,i,j)"                                  
                    maxlength="50"
                    :has-counter="false"
                  >
                  </b-input>
                </b-field>
                <div
                  v-if="posicao(linhas[i], colunas[j]).amostra"
                  class="cell-size"
                >
                  <p>
                    <b class="input-text-size">
                      {{
                        posicao(linhas[i], colunas[j]).amostra.pacienteNome
                      }}
                    </b>
                  </p>
                  <p class="cell-size">
                    <span
                      class="tag cell-size input-text-size"
                      v-for="ex in posicao(linhas[i], colunas[j]).apelidos"
                      :key="changeId(ex)"
                      :class="retornaClasse(ex)" :title="retornaTitle(ex)">
                        {{ ex.apelido }}
                    </span>
                  </p>
                </div>
                
                <input
                  type="hidden"
                  :value="
                    (model.posicoes[i * colunas.length + j].numero =
                      linhas[i])
                  "
                />
                <input
                  type="hidden"
                  :value="
                    (model.posicoes[i * colunas.length + j].letra =
                      colunas[j])
                  "
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <nav class="level">
      <div class="level-item">
        <p class="buttons">
          <b-button
            type="is-success"
            :loading="isLoading"
            native-type="submit"
            icon-left="check-circle"
            @click.prevent.stop="save"
            :disabled="!valores.length"
          >
            {{$t('SOROTECA.SALVAR')}}
          </b-button>
          <b-button
            type="is-warning"
            native-type="button"
            :loading="isLoading"
            icon-left="close-circle"
            @click="limparTodas()"
            :disabled="!valores.length"
          >
            {{$t('SOROTECA.LIMPAR')}}
          </b-button>        
        </p>
      </div>
    </nav>
  </section>
</template>

<style scoped>
.center {
  margin: 0 auto;
}

.cell-size {
    margin-bottom: 0.0 !important;
}

.cell-size {
  width: 7rem;
  /* font-size: 8px; */
}

table {
    display: block;
    overflow-x: auto;
}

.is-default {
  background-color: #777 !important;
  color: #fff !important;
}

.input-text-size {
  font-size: 0.8rem !important;
}
</style>

<script>
import titlebar from "@/components/titlebar.vue";
import searchIdName from "@/components/searchIdName.vue";
import zoom from "@/components/zoom.vue";
import { mapState } from 'vuex'
import uniqid from 'uniqid';

export default {
  components: {
    titlebar,
    searchIdName,
    zoom
  },
  data() {
    return {
      valores: [],
      model: {
        id: 0,
        nome: null,
        posicoes: [],
      },
      item: {
        geladeiraId: null,
        containerId: null,
      },            
      isLoading: false,      
      qtdeColunas: null,
      qtdeLinhas: null,
      colunas: [],
      linhas: [],
      amostraSelecionada: [],
      alfabeto: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
    };
  },

  mounted() {
    if(this.$route.query.amostraSelecionada)
    {
      const amostrasSelecionadas = this.$route.query.amostraSelecionada.split(",");

        amostrasSelecionadas.forEach((amostra, index) => {

            if(amostra && amostra.length >3){
              this.amostraSelecionada.push(amostra);
            }

        });      
      
    }
  },  
  computed: {
    ...mapState([
        'config',
    ]),
    titleStack() {
      return [this.$t('SOROTECA.ANALISE'), this.$t('SOROTECA.SOROTECAARMAZENAR')];
    },
  },
  methods: {
    getOrdem(totalColunas,i,j){
      
      return ((totalColunas*i) + j);

    },

    changeId(id = '') {
      return `input-${id}-${uniqid()}`;
    },
    retornaClasse(ex){

      if(ex.assinado){
        return 'is-success';
      }else if(ex.digitado){
        return 'is-warning';
      }else {
        return 'is-default';
      }
    
    },
    retornaTitle(ex){

      if(ex.assinado){
        return  this.$t('SOROTECA.RESULTADOASSINADO');
      }else if(ex.digitado){
        return this.$t('SOROTECA.RESULTADODIGITADO');
      }else {
        return '';
      }
    
    },    
    posicao(numero, letra) {
      const valor = this.valores?.find(
        (v) => v.numero === numero && v.letra === letra
      );
      if (!valor) {
        return { valor: null, error: null };
      }

      return valor;
    },
    limparTodas(){

           this.$buefy.dialog.confirm({
              title: this.$t('SOROTECA.ATENCAOTITULO'),
              message: this.$t('SOROTECA.REMOVERAMOSTRASTITULO'),
              type: 'is-warning',
              hasIcon: true,
              cancelText: this.$t('SOROTECA.NAO'),
              confirmText: this.$t('SOROTECA.SIM'),
              onConfirm: () => {

                this.valores = this.valores?.map(v => {
                    if (v.amostra){
                        v.amostra.codigoDeBarras = null;
                        v.amostra.amostraCodigoDeBarras = null;
                        v.deleted = true;
                        v.changed = true;
                    }
                    return v;
                });

                return this.save();

              }
            });

        
    },

    onKeyEnter(ordemAtual,numero, letra, codeBarras) {     
             
      if(ordemAtual >=0 && ordemAtual!=null ){
        const refOrdem = (ordemAtual+1);     

        this.buscarAmostra(numero, letra, codeBarras, refOrdem);                    
      }
            
    },    
    setarValor(ordemAtual,numero, letra, event) {

      const valor = event.target.value;      
      const item = this.valores?.find(
        (v) => v.numero == numero && v.letra == letra
      );
      if (!item) {
        this.valores.push({ numero: numero, letra: letra, valor: valor });
      } else {
        item.valor = valor;
      }

      if(item.amostra){
        item.amostra.amostraCodigoDeBarras = valor;
      }
      
      item.changed = true;
      if (!valor || valor?.trim() === "") {
        item.deleted = true;
      }

      this.onKeyEnter(ordemAtual,numero,letra,valor);
    },
    async buscarAmostra(numero, letra, codeBarras,refOrdem) {
      const item = this.valores?.find(
        (v) => v.numero === numero && v.letra === letra
      );
      
      if ( item && codeBarras && codeBarras!=null && codeBarras != '') {
        item.error = null;
        item.new = null;
        this.$http
          .get(
            `/api/analitico/SorotecaArmazenarContainerAmostra?codigoDeBarras=${codeBarras}`
          )
          .then((result) => {

            const amostraJaCadastrado = this.model.soroteca.some((el) => {
               return el.amostra.amostraCodigoDeBarras == codeBarras
            })
            
            if (!result.data?.length) {
                item.error = this.$t('SOROTECA.AMOSTRANAOENCONTRADA');
                item.valor = null;
                item.amostra = null;
                this.$forceUpdate();
                setTimeout(() => {        
                  this.$refs[`${refOrdem}`][0].focus();          
                }, 1000);                 
                            
            } else if(result.data[0].apelidos.filter(x => x.digitado === false).length>0) {
              const valor = result.data[0];
              const posicao = this.posicao(numero, letra);

              // TODO: trazer setor alguns tao vindo vazios
              this.$buefy.dialog.confirm({                
                message:
                  this.$t('SOROTECA.AMOSTRANAOPRONTACONFIRMAR') + 
                  '<hr><b>Setor</b>: '+valor.amostra.examesSetores+' <br> <b>Amostra</b>: '+ codeBarras ,
                onConfirm: () => {
                  item.error = null;
                  item.new = true;
                  item.changed = true;
                  item.valor = codeBarras;
                  item.geladeira = this.model.geladeira;
                  item.container = this.model.container;
                  item.posicao = {
                    id: posicao.id,
                    letra: posicao.letra,
                    numero: posicao.numero,
                    container: this.model.container,
                  };
                  item.amostra = {
                    id: valor.amostra?.amostraId,
                    codigoDeBarras: codeBarras,
                    pacienteNome: valor.amostra?.pacienteNome
                  };
                  item.apelidos = valor.apelidos;
                  setTimeout(() => {        
                    this.$refs[`${refOrdem}`][0].focus();
                  }, 500);                    
                  this.$forceUpdate();
                },
                cancelText: this.$t('SOROTECA.CANCELAR'),
                confirmText: this.$t('SISTEMA.CONFIRMAR'),
                onCancel: () => {
                  item.amostra = null;
                  item.error = this.$t('SOROTECA.AMOSTRANAODISPONIVEL');
                  item.valor = null;
        
                  setTimeout(() => {        
                    this.$refs[`${refOrdem}`][0].focus();          
                  }, 500);                     
                },
              });

            }else if (amostraJaCadastrado && !this.config.permitirInclusaoMesmaAmostraSoroteca){

               this.$buefy.dialog.alert({
                    title: 'Error',
                    message: this.$t('SOROTECA.AMOSTRAJACADASTRADA'),
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })

                this.$refs[`${refOrdem}`][0].value='';

                return;              
                        
            }else{

              const valor = result.data[0];
              const posicao = this.posicao(numero, letra);
                  item.error = null;
                  item.new = true;
                  item.changed = true;
                  item.valor = codeBarras;
                  item.geladeira = this.model.geladeira;
                  item.container = this.model.container;
                  item.posicao = {
                    id: posicao.id,
                    letra: posicao.letra,
                    numero: posicao.numero,
                    container: this.model.container,
                  };
                  item.amostra = {
                    id: valor.amostra?.amostraId,
                    codigoDeBarras: codeBarras,
                    pacienteNome: valor.amostra?.pacienteNome
                  };
                  item.apelidos = valor.apelidos;
                  this.$forceUpdate();  
                  
        
                  setTimeout(() => {        
                    this.$refs[`${refOrdem}`][0].focus();          
                  }, 500);                     


            }

          });
      }else{
          
          item.amostra = null;
          item.error = null;
          item.valor = null;        
          this.$forceUpdate();
      }
    },
    search() {
      if (!this.item.containerId && !this.item.geladeiraId) {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$t('SOROTECA.CONTAINERGELADEIRANAOSELECIONADO'),
          type: "is-danger",
          queue: false,
        });

        return;
      }
      const params = [];
      this.isLoading = true;
      Object.keys(this.item).forEach((key) => {
        const value = this.item[key];
        if (value) {
          params.push(`${key}=${value}`);
        }
      });
      this.$http
        .get(
          `/api/analitico/SorotecaArmazenarContainer?${params.join("&")}`,
          this.model
        )
        .then((response) => {          
          this.model = response.data;
          this.model.posicoes = response.data.container?.posicoes?.length
            ? [...response.data.container?.posicoes]
            : [];
          this.valores = [...response.data.container?.posicoes];
          delete response.data.container?.posicoes;
          this.geraColunasLinhas();
          this.popularValores(response.data.soroteca);
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          throw error;
        });

    },

    retornaColuna(posicao) {
      if (posicao >= this.alfabeto.length) {
        return (
          this.alfabeto[posicao - this.alfabeto.length] +
          (posicao % this.alfabeto.length)
        );
      } else {
        return this.alfabeto[posicao];
      }
    },
    geraColunasLinhas() {
      if (this.model.posicoes != null) {
        this.colunas = Array.from(
          this.groupBy(this.model.posicoes, (posicao) => posicao.letra).keys()
        );
        this.linhas = Array.from(
          this.groupBy(this.model.posicoes, (posicao) => posicao.numero).keys()
        );
      }
    },
    geraPosicoes() {
      const posicoes = [];
      for (let i = 1; i <= this.qtdeColunas; i++) {
        for (let j = 0; j < this.qtdeLinhas; j++) {
          posicoes.push({ id: 0, numero: i, letra: this.retornaColuna(j) });
        }
      }

      this.model.posicoes = posicoes;
      this.geraColunasLinhas();
    },
    groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    },
    popularValores(sorotecaArray) {
      sorotecaArray?.forEach((i) => {
        const valor = this.valores?.find(
          (v) => v.letra === i.posicao?.letra && v.numero === i.posicao?.numero
        );
        const amostraValor = i.amostra;
        if (valor && amostraValor) {
            valor.amostra = amostraValor;
            valor.apelidos = i.apelidos;
            valor.amostra.id = amostraValor.amostraId;
            valor.valor = amostraValor.amostraCodigoDeBarras;
            valor.codigoDeBarras = amostraValor.amostraCodigoDeBarras;
            valor.changed = false;
            valor.id = i.id;
            valor.sorotecaId = i.sorotecaId;
            valor.error = (i.apelidos.length==0 || i.apelidos[0].cancelado) ? this.$t('SOROTECA.AMOSTRANAOENCONTRADA') : null;
        }
    });
    },
    save() {
      this.isLoading = true;
      const data = this.valores
        ?.filter((i) => i.amostra && i.changed).map((v) => {
          const amostra = v.amostra;

          let id = !v.new ? v.id : 0;
          if (v.deleted){
              id = v.sorotecaId;
          }
          return {
            amostra: amostra,
            container: v.container,
            geladeira: this.model.geladeira,
            posicao: {
              id: v.id,
              letra: v.letra,
              numero: v.numero,
            },
            id: id,
          };
        });
      this.$http
        .post(`/api/analitico/SorotecaArmazenarContainer`, data)
        .then((response) => {          
          if (response.status === 200) {
                        
            this.$router.push({ name: 'soroteca-localizar' });
            
          } else {
            this.$buefy.toast.open({
              duration: 5000,
              message: this.$t('SOROTECA.AMOSTRAERROAOSALVAR'),
              type: "is-danger",
              queue: false,
            });            
          }
          this.isLoading = false;
        });
    },
  },
};
</script>
